import React from 'react';
import Layout from './src/components/common/layout';
import { Provider } from 'react-redux';
import store from './src/js/store/index';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
  toast.configure({
    autoClose: 3500,
    position: toast.POSITION.TOP_CENTER
  });
  return (
    <Provider store={store}>
      <Layout>{element}</Layout>
    </Provider>
  );
};
