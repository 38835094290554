import { SET_USER_ID, CLEAR_USER_ID, STORE_SEARCH_RESULTS, STORE_DOC_COUNT, STORE_LAST_SEARCH_QUERY } from '../constants/action-types';

const initialState = {
  userId: null,
  searchResults: null,
  docCount: null,
  lastSearchQuery: null
};

function rootReducer(state = initialState, action) {
  if (action.type === SET_USER_ID) {
    localStorage.setItem('userId', action.payload);
    return Object.assign({}, state, {
      userId: action.payload
    });
  }
  if (action.type === CLEAR_USER_ID) {
    localStorage.removeItem('userId');
    return Object.assign({}, state, {
      userId: undefined
    });
  }
  if (action.type === STORE_SEARCH_RESULTS) {
    return Object.assign({}, state, {
      searchResults: action.payload
    });
  }
  if (action.type === STORE_DOC_COUNT) {
    return Object.assign({}, state, {
      docCount: action.payload
    });
  }
  if (action.type === STORE_LAST_SEARCH_QUERY) {
    return Object.assign({}, state, {
      lastSearchQuery: action.payload
    });
  }
  return state;
}

export default rootReducer;
