import { CLEAR_USER_ID, SET_USER_ID, STORE_SEARCH_RESULTS, STORE_DOC_COUNT, STORE_LAST_SEARCH_QUERY } from '../constants/action-types';

export function setUserId(payload) {
  return { type: SET_USER_ID, payload };
}

export function clearUserId(payload) {
  return { type: CLEAR_USER_ID, payload };
}

export function storeSearchResults(payload) {
  return { type: STORE_SEARCH_RESULTS, payload };
}

export function storeDocCount(payload) {
  return { type: STORE_DOC_COUNT, payload };
}

export function storeLastSearchQuery(payload) {
  return { type: STORE_LAST_SEARCH_QUERY, payload };
}