/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

// https://til.hashrocket.com/posts/qm5abe6ay8-wrap-the-root-of-a-gatsby-app-in-a-component

import wrapWithProvider from './wrap-with-provider';
export const wrapRootElement = wrapWithProvider;
