import React from 'react';
import '../style.less';
import { Link } from 'gatsby';
import { Dropdown } from 'semantic-ui-react';

const Nav = props => (
  <nav className='nav-container'>
    <div className='nav-brand'>
      <Link to='/'>
        <strong>eslbot</strong>
      </Link>
    </div>
    <div style={{ flex: 1 }} />
    <div className='nav-links'>
      <Link to='/post-job'>post job</Link>
      {/* <Link to='/help'>help</Link> */}
      {props.userId ? <Link to='/account'>account</Link> : <Link to='/login'>login</Link>}
    </div>
    <Dropdown text='Menu' direction='left' className='nav-menu'>
      <Dropdown.Menu>
        <Dropdown.Item>
          <Link to='/post-job'>post job</Link>
        </Dropdown.Item>
        {/* <Dropdown.Item>
          <Link to='/help'>help</Link>
        </Dropdown.Item> */}
        {props.userId ? (
          <Dropdown.Item>
            <Link to='/account'>my account</Link>
          </Dropdown.Item>
        ) : (
          <Dropdown.Item>
            <Link to='/login'>login</Link>
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  </nav>
);

export default Nav;
