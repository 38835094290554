import React, { Component } from 'react';
import 'semantic-ui-less/semantic.less';
import '../style.less';
import Nav from './nav';
import { connect } from 'react-redux';
import { setUserId } from '../../js/actions/index';

class Layout extends Component {
  state = {
    userId: undefined
  };
  componentDidMount() {
    const userId = localStorage.getItem('userId');
    if (userId !== undefined && userId !== 'undefined' && userId !== null && userId !== 'null') {
      this.props.setUserId(userId);
    }
  }
  render() {
    return (
      <>
        <div className='body'>
          <Nav userId={this.props.userId} />
          {this.props.children}
        </div>
        {/* <div className='footer'>
          <p>
            <a href='mailto:admin@eslbot.com'> &copy; eslbot 2020</a>
          </p>
        </div> */}
      </>
    );
  }
}

const mapStateToProps = state => {
  return { userId: state.userId };
};

const mapDispatchToProps = dispatch => {
  return {
    setUserId: userId => dispatch(setUserId(userId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
